var octicons = require("@primer/octicons");
import "../../node_modules/@primer/octicons/build/build.css";

$(".octicon-pencil").html(octicons.pencil.toSVG());
$(".octicon-trash").html(octicons.trashcan.toSVG());
$(".octicon-check").html(octicons.check.toSVG());
$(".octicon-key").html(octicons.key.toSVG());
$(".octicon-check-green").prepend(octicons.check.toSVG({ "class": "mr-3 text-success float-left h-100" , "height" : "40", "stroke-width": "3px"}));
$(".octicon-arrow-right").append(octicons["arrow-right"].toSVG({ "class": "ml-2" , "height" : "20"}));
$(".octicon-arrow-right-sm").append(octicons["arrow-right"].toSVG({ "class": "ml-1" , "height" : "16"}));
$(".octicon-arrow-right-lg").append(octicons["arrow-right"].toSVG({ "class": "ml-3" , "height" : "25"}));
$(".octicon-arrow-left").prepend(octicons["arrow-left"].toSVG({ "class": "mr-2" , "height" : "20"}));
$(".octicon-arrow-left-sm").prepend(octicons["arrow-left"].toSVG({ "class": "mr-1" , "height" : "16"}));
$(".octicon-arrow-left-lg").prepend(octicons["arrow-left"].toSVG({ "class": "mr-3" , "height" : "25"}));
$(".octicon-search").prepend(octicons.search.toSVG({ "class": "mr-2" , "height" : "20"}));
$(".octicon-chevron-up-secondary").html(octicons["chevron-up"].toSVG({ "class": "text-secondary" }));
$(".octicon-chevron-down-secondary").html(octicons["chevron-down"].toSVG({ "class": "text-secondary" }));
$(".octicon-book").html(octicons.book.toSVG());
$(".octicon-desktop-download").append(octicons["desktop-download"].toSVG({ "class": "ml-2" , "height" : "20"}));
$(".octicon-light-bulb").prepend(octicons["light-bulb"].toSVG({ "class": "mr-2" , "height" : "30"}));
$(".octicon-eye").prepend(octicons["eye"].toSVG({ "class": "mr-2 text-success" , "height" : "20"}));
$(".octicon-star").prepend(octicons["star"].toSVG({ "class": "mr-2 text-primary" , "height" : "20"}));
$(".octicon-checklist").html(octicons["checklist"].toSVG({ "class": "text-secondary" , "height" : "50"}));
$(".octicon-book").html(octicons["book"].toSVG({ "class": "text-secondary" , "height" : "50"}));
$(".octicon-list-ordered").html(octicons["list-ordered"].toSVG({ "class": "text-secondary" , "height" : "50"}));
$(".octicon-briefcase").html(octicons["briefcase"].toSVG({ "class": "text-secondary" , "height" : "50"}));
$(".octicon-graph").html(octicons["graph"].toSVG({ "class": "text-secondary" , "height" : "50"}));
$(".octicon-project").html(octicons["project"].toSVG({ "class": "text-secondary" , "height" : "50"}));
$(".octicon-milestone").html(octicons["milestone"].toSVG({ "class": "text-secondary" , "height" : "50"}));
$(".octicon-dot").html(octicons["dot"].toSVG({ "class": "text-secondary" , "height" : "50"}));
$(".octicon-arrow-up-right").html(octicons["arrow-up-right"].toSVG({ "class": "text-secondary" , "height" : "50"}));
$(".octicon-mail").prepend(octicons["mail"].toSVG({ "class": "mr-5" , "height" : "50"}));
$(".octicon-mobile").prepend(octicons["device-mobile"].toSVG({ "class": "mr-5" , "height" : "50"}));

$("div").on("hidden.bs.collapse", function () {
  $(this).siblings('div.card-header').children("button").html(octicons["chevron-down"].toSVG({ "class": "text-secondary" }));
});

$("div").on("shown.bs.collapse", function () {
  $(this).siblings('div.card-header').children("button").html(octicons["chevron-up"].toSVG({ "class": "text-secondary" }));
});

$("dl.starlist dd").prepend(octicons["star"].toSVG({ "class": "mr-2 text-primary" , "height" : "20"}));
$("dl.starlisttitle dt").prepend(octicons["star"].toSVG({ "class": "mr-2 text-primary" , "height" : "20"}));

$("dl.qlist dd").prepend(octicons["question"].toSVG({ "class": "mr-2 text-primary" , "height" : "20"}));

let check = '<svg xmlns="http://www.w3.org/2000/svg" width="24.657" height="18.387" viewBox="0 0 24.657 18.387" class="float-left h-100 "><defs><style>.a{fill:#3de39b;}</style></defs><path class="a" d="M8.375,22.6.361,14.59a1.233,1.233,0,0,1,0-1.744L2.1,11.1a1.233,1.233,0,0,1,1.744,0l5.4,5.4L20.809,4.938a1.233,1.233,0,0,1,1.744,0L24.3,6.682a1.233,1.233,0,0,1,0,1.744L10.118,22.6A1.233,1.233,0,0,1,8.375,22.6Z" transform="translate(0 -4.577)" /></svg>';

$(".fa-check").prepend(check);
